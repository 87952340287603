// example ReportsService.get({ from_date: 1485388800000, to_date: 1491239622804 }, function(result) { console.log(result); });

angular.module('sc.services.reports', [
  'sc',
  'ngResource'
])
.factory('SummaryReportsService', /*@ngInject*/ function($http, $resource, $scSystem) {
  var baseRoute = $scSystem.baseUrl+'/v1/accounts/:accountId/users/:userId/reports/summary/:interval';

  var Reports = $resource(baseRoute, {
    accountId: function() { return sessionStorage.accountId; },
    userId: function() { return sessionStorage.userId; },
    interval: 'hourly'
  }, {

  });

  return Reports;
})
.factory('DetailReportsService', /*@ngInject*/ function($http, $resource, $scSystem) {
  var baseRoute = $scSystem.baseUrl+'/v1/accounts/:accountId/users/:userId/reports/detail';

  function transformResponse(data) { //headersGetter, status unused parameters here
    var collection = angular.fromJson(data);
    if (collection && collection.userRecords && collection.userRecords[$scSystem.userId]) {
      return collection.userRecords[$scSystem.userId].eventHistoryRecords;
    } else return [];
  }

  var DetailedReport = $resource(baseRoute, {
    accountId: function() { return sessionStorage.accountId; },
    userId: function() { return sessionStorage.userId; }
  }, {
    query: {
      transformResponse: transformResponse,
      isArray: true
    },
    count: {
      method: 'GET',
      url: baseRoute+'/count'
    }
  });

  DetailedReport.prototype.isActive = function() {
    return (['RINGING', 'ACTIVE', 'INITIALIZING', 'HELD', 'REMOTE_HELD'].indexOf(this.state) !== -1);
  };

  // DetailedReport.prototype.durationInSeconds = function() { // < 1s show 1 second
  //   return (this.duration < 1000 ? 1 : this.duration/1000);
  // };

  return DetailedReport;
});