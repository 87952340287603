angular.module('sc.services.unified', [

])
  .factory('UnifiedService', /*@ngInject*/ function ($resource, $scSystem) {
    var baseRoute = $scSystem.baseUrl + '/v2/accounts/:accountId/users/:userId';
    function deepNormalizeObject(obj) {
      if (typeof(obj) === 'object') {
        for (var p in obj) {
          if(obj[p]) {
            var prop = obj[p];
            if (prop instanceof Date) {
              if (prop.patchTimezone) {
                obj[p] = new Date(prop.getTime() - (prop.getTimezoneOffset() * 60000)).getTime();
              } else {
                obj[p] = prop.getTime();
              }
            }
          }
        }
      }
      return obj; //optional return, the object is modified in memory
    }
    function transformRequest(data) {  //removed unused parameter; headersGetter
      var trData = {};
      // console.log('before', data);
      for (var property in data.toJSON()) {
        if (data[property] instanceof Date) {
          if (data[property].valueType === 'isostring') {
            trData[property] = data[property].toISOString();
          } else {
            if (data[property].patchTimezone) {
              trData[property] = new Date(data[property].getTime() - (data[property].getTimezoneOffset() * 60000)).getTime();
            } else {
              trData[property] = data[property].getTime();
            }
          }
        } else if (data[property] && (data[property] instanceof Array || data[property].value || data[property].id || data[property].label)) {
          if (data[property].valueArray) {
            if (data[property].valueType === 'object') {
              trData[property] = [];
              if (data[property] instanceof Array) {
                for (var k = 0; k < data[property].length; k++) {
                  if (data[property][k] !== null && typeof(data[property][k]) === 'object' && Object.keys(data[property][k]).length) {
                    trData[property].push({
                      id: data[property][k].value || data[property][k].id,
                      label: data[property][k].label || data[property][k].name || 'Unknown',
                      type: data[property][k].attributes ? data[property][k].attributes.type : data[property][k].type || data[property][k].resourceType,
                      value: data[property][k].value || data[property][k].id
                    });
                  }
                }
              } else {
                trData[property].push({
                  id: data[property].value || data[property].id,
                  label: data[property].label || data[property].name || 'Unknown',
                  type: data[property].attributes ? data[property].attributes.type : data[property].type || data[property].resourceType,
                });
              }
            } else {
              trData[property] = [];
              if (data[property] instanceof Array) {
                for (var i = 0; i < data[property].length; i++) {
                  if (data[property][i] !== null && typeof(data[property][i]) === 'object' && Object.keys(data[property][i]).length) {
                    if (Object.keys(data[property][i]).filter(function(n) { return ['id', 'value'].indexOf(n) === -1}).length) {
                      trData[property].push(deepNormalizeObject(data[property][i]));
                    } else {
                      trData[property].push(data[property][i].value || data[property][i].id || deepNormalizeObject(data[property][i]));
                    }
                  } else if (data[property][i] != null && typeof(data[property][i]) === 'string') {
                    trData[property].push(data[property][i]);
                  }
                }
              } else trData[property].push(data[property].value || data[property].id);
            }
          } else if (data[property].valueType === 'object') {
            trData[property] = {
              id: data[property].value || data[property].id,
              label: data[property].label || data[property].name || data[property].Name || 'Unknown',
              type: data[property].attributes ? data[property].attributes.type : data[property].type || data[property].resourceType || data[property].reference,
            };
          } else if (data[property].value || data[property].id || data[property].label) {
            if (property === '_vgis') {
              trData[property] = data[property];
            } else if (data[property].valueType === 'string') { //VGIS-4387
              trData[property] = data[property].value || data[property].id;
            } else { //for all integrations object is object
              trData[property] = {
                id: data[property].value || data[property].id,
                label: data[property].label || data[property].name || data[property].Name || 'Unknown',
                type: data[property].attributes ? data[property].attributes.type : data[property].type || data[property].resourceType || data[property].reference,
              };
            }
          } else {
            if (data[property].length) {
              for (var e = 0; e < data[property].length; e++) {
                deepNormalizeObject(data[property][e]);
              }
              if (data[property] instanceof Array) {
                trData[property] = [];
                for (var j = 0; j < data[property].length; j++) {
                  if (typeof(data[property][j]) === 'object') { //TODO verify verify!!!
                    deepNormalizeObject(data[property][j]);
                    if (j > 0 && typeof(data[property][0]) === 'string') {
                      trData[property].push(data[property][j].value || data[property][j]);
                    } else {
                      trData[property].push(data[property][j]);  //data[property][j].value || data[property][j].id || data[property][j].name || 
                    }
                  } else {
                    trData[property].push(data[property][j]);
                  }
                }
                if (!trData[property].length) {
                  delete trData[property];
                } 
              } else if (typeof(data[property]) === 'object') {
                trData[property] = data[property].id || data[property].value || data[property].name || data[property];
              } else {
                trData[property] = data[property];
              }
            }
          }
        } else if (data[property] || data[property] === '' || data[property] === 0 || data[property] === false) {
          trData[property] = data[property];
        }
        
        //nested transform
        if (trData[property] && property.indexOf('.') !== -1) {
          var value = trData[property];
          delete trData[property];
          var nests = property.split('.');
          var root = trData;
          for (var n = 0; n < (nests.length - 1); n++) {
            if (!root[nests[n]]) {
              root[nests[n]] = {};
            }
            root = root[nests[n]];
          }
          if(root !== null && typeof(root) !== 'undefined') {
            if (value instanceof Date) {
              if (value.patchTimezone) {
                root[nests[nests.length - 1]] = new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).getTime();
              } else {
                root[nests[nests.length - 1]] = value.getTime();
              }
            } else if (typeof(value) === 'object' && data[property] && data[property].valueType !== 'object') { // for now - no support special valueType in nested properties
              // posssible support for diff valueTypes here
              root[nests[nests.length - 1]] = value.id || value.value || value.name || value;
            } else {
              root[nests[nests.length - 1]] = value;
            }
          }
        }
        if (property === '_vgis') { //setting timezoneOffset in _vgis
          trData[property].timeZoneOffsetInMinutes = 0; // new Date().getTimezoneOffset();
          // we are not providing timeone anymore (as V3), instead the 'time' and 'date' objects are converted so the UTC time/date is correct
        }
      }
      // console.log('after', trData);
      return angular.toJson(trData);
    }

    function convertNestedProps(parent, property, kv) {
      var prop = parent[property];
      kv.k = kv.k ? kv.k + '.' + property : property;
      if (prop !== null && typeof (prop) === 'object' && !(prop.label && prop.id)) {
        for (var pc in prop) {
          if(prop[pc]) {
            convertNestedProps(prop, pc, kv);
          }
        }
      } else {
        kv.v = prop;
      }
    }

    function transformResponse(data) {  //removed unused parameters; headersGetter, status
      var response = angular.fromJson(data);
      if (typeof (response) === 'object') {
        for (var pr in response) {
          if(pr && pr !== '_vgis') {
            var props = response[pr];
            if (props !== null && typeof (props) === 'object' && !(props.label && props.id) && !(props instanceof Array)) {
              for (var prop in props) {
                if(props[prop]) {
                  var kv = { k: '', v: null };
                  convertNestedProps(props, prop, kv);
                  response[pr + '.' + kv.k] = kv.v;
                  // console.log('kv', pr + '.' + kv.k, response[pr + '.' + kv.k]);
                }
              }
            }
          } 
        }
      }
      return response;
    }

    var Service = function (config, actions) {
      var defaultActions = {
        describe: {
          method: 'GET',
          params: { id: 'metadata', t: function() { return new Date().getTime(); } },
          // transformResponse: function(data, headersGetter, status) {
          //   var response = angular.fromJson(data);
          //   return response.layout;
          // }
          url: config.connector == 'mock' ? './mock/metadata/:resourceType' : null
        },
        get: {
          transformResponse: transformResponse
        },
        count: {
          method: 'GET',
          url: baseRoute + '/connectors/:connector/:resourceType/count',
        },
        search: {
          method: 'GET',
          isArray: true,
          url: baseRoute + '/contacts'
        },
        create: {
          transformRequest: transformRequest,
          method: 'POST'
        },
        update: {
          transformRequest: transformRequest,
          method: 'PUT'
        },
        layouts: {
          method: 'GET',
          url: baseRoute + '/connectors/:connector/:resourceType/layouts',
          isArray: true,
          transformResponse: function (data) {
            if (data) {
              var layouts = angular.fromJson(data);
              if (layouts.records) {
                return layouts.records;
              } 
            }
          }
        }
      };
      if (!actions) {
        actions = {};
      } 

      var resource = $resource(baseRoute + '/connectors/:connector/:scParentType/:scParentId/:resourceType/:id', {
        accountId: function () { return $scSystem.accountId; },
        userId: function () { return $scSystem.userId; },
        connector: function () {
          if (!config.connector) {
            throw 'UnifiedService connector is not configured';
          } 
          return config.connector;
        },
        resourceType: function () {
          if (!config.connector) {
            throw 'UnifiedService resource is not configured';
          }
          return config.resource;
        },
        id: function (that) {
          if (that) {
            return that._getId;
          }
        },
        scParentType: function(that) {
          return (that && that.scParentType) ? that.scParentType : config.scParentType;
        },
        scParentId: function(that) {
          return (that && that.scParentId) ? that.scParentId : config.scParentId;
        }
      }, {
          get: actions.get || defaultActions.get,
          describe: actions.describe || defaultActions.describe,
          search: actions.search || defaultActions.search,
          create: actions.create || defaultActions.create,
          layouts: actions.layouts || defaultActions.layouts,
          update: actions.update || defaultActions.update,
          count: actions.count || defaultActions.count
        });

      // resource.prototype._getId = function() {
      //   return this._vgis ? this._vgis.externalId : this.id || this.Id;
      // };
      Object.defineProperty(resource.prototype, '_getId', {
        get: function () {
          return this._vgis ? this._vgis.externalId || this._vgis.id : this.id;
        }
      });

      resource.prototype.$save = function (success, error) {
        return this._getId ? this.$update(success, error) : this.$create(success, error);
      };

      resource.prototype.toPayload = function() {
        return transformRequest(this);
      };

      resource.prototype.metadata = function(params) {
        var metaQuery = { id: 'metadata', t: new Date().getTime() };
        if (!params) {
          params = {};
        }
        metaQuery.layoutId = params.layoutId || (this._vgis ? this._vgis.layoutId : null);
        metaQuery.eventId = params.eventId || (this._vgis ? this._vgis.eventId : null);
        metaQuery.refresh = params.refresh || null;
        return resource.get(metaQuery).$promise;
      };

      resource.new = function (defaults) {
        if (defaults.then) {
          return defaults.then(function (d) {
            return new resource(d);
          });
        } else return new resource(defaults);
      };
      return resource;
    };

    return Service;
  });