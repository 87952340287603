module.exports = /*@ngInject*/ function ($scope, $flash, $scConnectors, $mdToast, ContactsService, UnifiedService, CallsService, Analytics) {
  var self = this;
  
  var resourceType;
  var connector;
  connector = $scope.scContact ? ($scope.scContact.connector || '').toLowerCase() || 'gunify' : 'gunify';
  if ($scope.scContact && ($scope.scContact.type || $scope.scContact.resourceType)) {
    resourceType = $scope.scContact.type || $scope.scContact.resourceType;
  }
  //fallback to gUnify contact if record is incomplete
  if (!resourceType) {
    connector = 'gunify';
    resourceType = 'Contact';
  }
  var IntegrationContactService = new UnifiedService({ 
    connector: connector, 
    resource: resourceType
  });
  //var storedLayoutId = localStorage.getItem($scSystem.userId + '.' + connector + resourceType + 'LayoutId');

  function buildContact(persistedContact) {
    self.contact = new IntegrationContactService(angular.copy(persistedContact || $scope.scContact));
    self.contact.connector = ($scope.scContact && $scope.scContact.connector) ? $scope.scContact.connector.toLowerCase() : 'gunify';
    self.contact.type = ($scope.scContact && $scope.scContact.type) ? $scope.scContact.type : 'Contact';
    self.operation = 'edit';
  }
  if ($scope.scContact && ($scope.scContact.identifier || $scope.scContact.id)) {
    //new IntegrationContactService.get({ id: $scope.scContact.identifier || $scope.scContact.id, layoutId: storedLayoutId, referenceDetails: true }, function (contact) {
    new IntegrationContactService.get({ id: $scope.scContact.identifier || $scope.scContact.id, referenceDetails: true }, function (contact) {
      buildContact(contact);
    }, function (error) {
      if (error.status === 404) {
        self.error = 'The requested contact does not exist in the remote system. You can create a new one.';
      }
      buildContact();
      delete $flash.error;
      delete self.contact.id;
      delete self.contact.identifier;
      if (self.contact._vgis) {
        delete self.contact._vgis.externalId;
        delete self.contact._vgis.id;
      }
    });
  } else buildContact();
  
  if ($scope.forCall) {
    self.forCall = angular.copy($scope.forCall);
    self.phone = {
      phoneNumber: self.forCall.phoneNumber
    };
  }

  self.loading = true;
  IntegrationContactService.describe(function (response) {
    self.loading = false;
    self.scDescribe = response.layout;
    self.niceFields = {};
    for (var s in self.scDescribe.sections) {
      if (self.scDescribe.sections[s]) {
        var section = self.scDescribe.sections[s];
        if (!section.fields || !section.fields.length) {
          break;
        }
        for (var f in section.fields) {
          if (section.fields[f]) {
            var field = section.fields[f];
            if (field.type !== 'composite') {
              self.niceFields[field.name] = field;
            } else if (field.fields.length) {
              for (var ff = 0; ff < field.fields.length; ff++) {
                self.niceFields[field.fields[ff].name] = field.fields[ff];
              }
            }
          }
        }
      }
    }
  });

  self.save = function () {
    delete self.error;
    if (self.contactForm.$valid) {
      self.loading = true;
      if (!self.contact._vgis) {
        self.contact._vgis = {
          resourceType: resourceType,
          connector: connector
        };
      }
      if(self.contact._getId) {
        for (var p in self.contact.toJSON()) {
          if(p && p !== '_vgis') {
            var fMeta = self.niceFields[p];
            if (!fMeta || fMeta.updateable === false) {
              delete self.contact[p];
            }
          }
        }
      }
      if (self.forCall && self.forCall.eventId) {
        self.contact._vgis.eventId = self.forCall.eventId;
      }
      self.contact.$save().then(function (contact) {
        self.contact.id = contact.id || contact._vgis.id || contact._id;
        if (resourceType === 'Contact' && connector.toUpperCase() === 'GUNIFY') {
          var jsonContact = contact.toJSON();
          for (var key in jsonContact) {
            $scope.scContact[key] = jsonContact[key];
          }
        } else {
          if (contact._vgis.id) {
            ContactsService.get({ id: contact._vgis.id }, function(updatedContact) {
              var jsonContact = updatedContact.toJSON();
              for (var key in jsonContact) {
                $scope.scContact[key] = jsonContact[key];
              }
              self.loading = false;
            }, angular.noop);
          }
        }
        $mdToast.show(
          $mdToast.simple().textContent('Contact saved successfully.').position('bottom left').hideDelay(1000)
        );
        if (self.forCall && self.forCall.eventId) {
          gU.scSendMessage({ action: 'broadcastMsg', data: { event: 'call', data: self.forCall.toJSON() } });
        }
        self.loading = false;
        Analytics.trackEvent('panel', 'contact', 'saveSuccess', contact._vgis.id);
      }, function (error) {
        self.error = error.data ? error.data.message || error.data.error : error;
        self.loading = false;
        // buildContact(); //Temporary fix until backend response change
        Analytics.trackEvent('panel', 'contact', 'saveFailure', self.contact._getId);
      });
      self.contactForm.$setPristine();
    } else Analytics.trackEvent('panel', 'contact', 'validationFailure', self.contact.id);
  };

  self.querySearch = function (query) {
    return ContactsService.query({ q: query }).$promise.then(function (result) {
      var queryResult = [];
      if (result && result.length > 0) {
        result.forEach(function (item) {
          if (item.phoneNumbers && item.phoneNumbers.length > 0) {
            item.phoneNumbers.forEach(function (phone) {
              queryResult.push({
                name: item.name || item.displayName || 'Unknown',
                phoneNumber: phone.phoneNumber,
                phoneType: phone.phoneType,
                connector: item.provider || item.connector,
                id: item.id
              });
            });
          }
        });
      }
      self.searchResultsCount = queryResult.length;
      return queryResult;
    }, function (err) { console.error(err); });
  };

  self.addToContact = function () {
    self.loading = true;
    ContactsService.get({ id: self.selectedContact.id }, function (eContact) {
      if (!eContact.phoneNumbers) {
        eContact.phneNumbers = [];
      }
      eContact.connector = eContact.connector ? eContact.connector.toLowerCase() : 'gunify';
      eContact.type = eContact.type || eContact.associationType || 'Contact';

      function mergeContact(contact) {
        var jsonContact = contact.toJSON();
        for (var key in jsonContact) {
          $scope.scContact[key] = jsonContact[key];
        }
        $scope.scContact.connector = eContact.connector;
        $scope.scContact.type = eContact.type;
        $mdToast.show(
          $mdToast.simple().textContent('Contact updated successfully.').position('bottom left').hideDelay(1000)
        );
        buildContact();
        if (!self.contact.id) {
          self.contact.id = self.contact.contactId; // BE APIs inconsistent id and contactId!!!
        }
        if (self.forCall && self.forCall.eventId) {
          CallsService.assignContact({ eventId: self.forCall.eventId, contactId: self.contact.id }, function () {
            self.forCall.contact = self.contact.toJSON();
            gU.scSendMessage({ action: 'broadcastMsg', data: { event: 'call', data: self.forCall.toJSON() } });
          });
        }
      }
      var phoneExists = false;
      for (var p = 0; p < eContact.phoneNumbers.length; p++) {
        if (eContact.phoneNumbers[p] && eContact.phoneNumbers[p].phoneNumber === self.phone.phoneNumber) {
          phoneExists = true;
          break;
        }
      }
      if (!phoneExists) {
        eContact.phoneNumbers.push(self.phone);
        eContact.$save(function (contact) {
          mergeContact(contact);
          self.loading = false;
        });
      } else {
        mergeContact(eContact);
        self.loading = false;
      }
    });
  };

  self.brandIcon = function (brand) {
    if (brand) {
      var connector = $scConnectors.all[brand.toLocaleLowerCase()];
      if (connector) {
        return connector.iconSmall;
      }
    }
  };
};