angular.module('sc.services.callRecordings', [
  'sc',
  'ngResource',
  'sc.services.auth2'
]).factory('CallRecordingsService', /*@ngInject*/ function ($http, $resource, $scSystem) {
  var baseRoute = $scSystem.baseUrl + '/v3/accounts/:accountId/users/:userId/calls/:callId';

  var CallRecordings = $resource(baseRoute + '/recordings/:id', {
    accountId: function () { return $scSystem.accountId; },
    userId: function () { return $scSystem.userId; },
    callId: '@callId',
    id: '@id'
  }, {
  
  });

  Object.defineProperty(CallRecordings.prototype, 'audioUrl', {
    get: function () {
      return $scSystem.baseUrl + '/v3/accounts/' + $scSystem.accountId + '/users/' + $scSystem.userId + '/calls/' + this.callId + '/recordings/' + this.id + '?access_token=' + encodeURIComponent((sessionStorage.getItem('accessToken') || sessionStorage.getItem('token')));
    }
  });

  return CallRecordings;
});