angular.module('sc.services.providerAuth', [
  'sc',
  'ngResource'
])
.factory('ProviderAuthService', /*@ngInject*/ function($resource, $scSystem) {
  return $resource($scSystem.baseUrl+'/v1/auth/ucp/:provider/login', {
    provider: '@provider', 
    state: '@state' // wrong query param for POST required for register api
  }, {
    save: {
      method: 'POST',
      transformResponse: function(data, headersGetter, status) {
        var response = angular.fromJson(data);
        response.$status = status;
        return response;
      }
    },
    register: {
      method: 'POST',
      url: $scSystem.baseUrl+'/v1/auth/ucp/:provider/register?state=:state',
      transformResponse: function(data, headersGetter, status) {
        var response = angular.fromJson(data);
        response.$status = status;
        return response;
      }
    },
    revoke: {
      method: 'DELETE',
      url: $scSystem.baseUrl+'/v2/auth/:provider'
    }
  });
});