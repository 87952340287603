module.exports = /*@ngInject*/ function ($rootScope, $scope, $state, corePort, $scSystem, $mdToast, $windowHelper, Analytics) {
  var self = this;

  $scope.scBrowser = scBrowser;
  $scope.goto = function (state, params) { //for non href navigation
    $state.go(state, params);
  };
  $scope.showToast = function (text, position) {
    $mdToast.show(
      $mdToast.simple().textContent(text).position(position || 'top right').hideDelay(3000)
    );
  };

  self.clickOpenPortal = function() {
    $windowHelper.openPopup(location.origin, { scType: 'normal' });
    Analytics.trackEvent('core', 'clickOpenPortal');
  };

  self.clickOpenPage = function (ev, url, openWithHash) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    var popupOptions = { scType: 'normal' };
    if(openWithHash) popupOptions.urlIncludesHash = true;
    $windowHelper.openPopup(url, popupOptions);
    Analytics.trackEvent('core', 'clickOpenPage', url);
  };
  $rootScope.clickOpenPage = self.clickOpenPage;
  // corePort.onMessage.addListener(function(msg) {
  //   if (msg.event && msg.event === 'scSystemChanged') {
  //     require('../background/utils/scSystem')(function(sys) {
  //       $scSystem.baseUrl = sys.baseUrl;
  //       $scSystem.token = sys.token;
  //       $scSystem.refreshToken = sys.refreshToken;
  //       $scSystem.userId = sys.userId;
  //       $scSystem.accountId = sys.accountId;
  //     });
  //   }
  // });
  $rootScope.isBrowser = isBrowser;
};