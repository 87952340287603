angular.module('sc.services.messages', [
  'sc',
  'ngResource',
  'sc.services.auth2'
]).factory('MessagesService', /*@ngInject*/ function ($resource, $scSystem) {
  var baseRoute = $scSystem.baseUrl + '/v3/accounts/:accountId/users/:userId/ucis/:uciId/:id';
 
  var Messages = $resource(baseRoute + '/messages', {
    accountId: function () { return $scSystem.accountId; },
    userId: function () { return $scSystem.userId; }
  }, {
    getData: {
      url: $scSystem.baseUrl + '/v3/accounts/:accountId/users/:userId/events/:id/data',
      method: 'GET'
    }
  });

  return Messages;
});