var webPort = require('../common/helpers/webPort');

var scMsglisteners = {};
window.scMsglisteners = scMsglisteners; //DEV expose

function removeExpiredListeners() {
  var now = new Date();
  for (var state in scMsglisteners) { //remove expired listeners (< 1 min ago);
    if (state < now.setMinutes(now.getMinutes() - 1)) delete scMsglisteners[state];
  }
}

window.addEventListener("message", function(event) {
  var origin = event.origin || event.originalEvent.origin; // For Chrome, the origin property is in the event.originalEvent object.
  if (origin !== location.origin) return;
  if (event && event.data) {
    if (event.data.state && event.data.scMsgResp) {
      var callback = scMsglisteners[event.data.state];
      if (callback) callback(event.data.scMsgResp);
      delete scMsglisteners[event.data.state];
    } else if (event.data.scCorePortMsg) {
      webPort.broadcastMsg(event.data.scCorePortMsg);
    }
  }
  removeExpiredListeners();
}, false);

gU.scSendMessage = function(msg, callback, isLong) {
  if (msg) {
    if (gU.storage && gU.runtime) {
      gU.runtime.sendMessage(msg, callback);
    } else {
      var state; 
      do {
        state = new Date().getTime();
      } while (scMsglisteners[state]);
      var cancelMsgListener = function(state) {
        var callback = scMsglisteners[state];
        if (callback) callback({ action: msg.action, error: 'timeout' });
        delete scMsglisteners[state];
      };
      scMsglisteners[state] = callback;
      window.postMessage({ scMsgReq: msg, state: state }, location);
      setTimeout(function() {
        cancelMsgListener(state);
      }, isLong ? 60000 : 6000);
    }
  }
};