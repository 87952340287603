angular.module('sc.services.fastdial', [
  'sc',
  'ngResource',
  'sc.services.auth2'
]).factory('FastDialService', /*@ngInject*/ function ($resource, $scSystem) {
  var baseRoute = $scSystem.baseUrl + '/v1/accounts/:accountId/users/:userId/fastdials';

  var FastDial = $resource(baseRoute + '/:id', {
    accountId: function () { return $scSystem.accountId; },
    userId: function () { return $scSystem.userId; },
    id: '@id'
  }, {
    count: {
      method: 'GET',
      url: baseRoute + '/count'
    },
    create:{
      method: 'POST'
    },
    update: {
      method: 'PUT'
    }
  });

  FastDial.prototype.$save = function(success, error) {
    return (this.id) ? this.$update(success, error) : this.$create(success, error);
  };

  return FastDial;
});