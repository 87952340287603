var scStorage = require('./scStorage');

module.exports = {
  get: function(windowType, callback) {
    var size = { h: 608, w: 890 };
    var storageKey = '';
    switch(windowType) {
      case 'panel':
        size.h = 608;
        size.w = 374;
        storageKey = 'scPanelSize';
        break;
      case 'activity':
        size.h = 608;
        size.w = 890;
        storageKey = 'scActivitySize';
        break;
    }
    if (!storageKey) return callback(size);
    else return scStorage.local.get([storageKey, 'scActivityAppearance'], function(keys) {
      var dims = keys[storageKey];
      if (windowType === 'activity' && keys.scActivityAppearance === 'tab') {
        dims = { openInTab: true };
      }
      callback(dims || size);
    });
  }
};