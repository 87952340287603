module.exports = /*@ngInject*/ function($mdDialog, $scSystem, $http, $flash, Analytics) {
  var self = this;

  this.cancel = function() {
    $mdDialog.cancel();
  };

  self.activate = function() {
    delete self.error;
    self.activationForm.$setUntouched();
    Analytics.trackEvent('setup', 'click', 'activate');
    if (self.activationForm.$invalid) {
      return;
    }
    self.loading = true;
    $http({
      url: $scSystem.baseUrl.replace('/v1', '')+'/v2/auth',
      method: 'POST',
      data: { 
        activationCode: self.activationCode
      },
      skipAuthorization: true
    }).then(function(response) {
      $mdDialog.hide(response.data);
      Analytics.trackEvent('setup', 'activate', 'success');
    }, function(error) {
      self.loading = false;
      if (error && error.status === 403 || error.status === 404) { //protocol contact changed!!!
        delete $flash.error;
        self.activationForm.activationCode.$setValidity("invalid", false);
      }
      Analytics.trackEvent('setup', 'activate', 'failure', error.status);
    });
  };
};