angular.module('sc.services.contacts', [
  'sc',
  'ngResource'
])
.factory('ContactsService', /*@ngInject*/ function($http, $resource, $scSystem) {
  // if (scDebug) {
  //   console.warn('init ContactsService');
  // }
  var baseRoute = $scSystem.baseUrl+'/v1/accounts/:accountId/users/:userId';

  //unused function
  // function transformResponse(data, headersGetter, status) {
  //   var collection = angular.fromJson(data);
  //   if (collection.contacts) {
  //     return collection.contacts;
  //   }
  //   else return collection;
  // }
  
  var Contacts = $resource(baseRoute+'/contacts/:id', {
    accountId: function() { return $scSystem.accountId; },
    userId: function() { return $scSystem.userId; },
    id: '@id',
    integration: '@connector',
    resourceType: '@type'
  }, {
    create:{
      method: 'POST',
      url: $scSystem.baseUrl+'/v2/accounts/:accountId/users/:userId/contacts/gunify/Contact',
      transformResponse: function(data) {
        var contact = angular.fromJson(data);
        if (!contact.id) {
          contact.id = contact._id;
        }
        return contact;
      }
    },
    update: {
      method: 'PUT',
      url: $scSystem.baseUrl+'/v2/accounts/:accountId/users/:userId/contacts/:integration/:resourceType/:id'
    },
    query: {
      method: 'GET',
      isArray: true,
      // url: $scSystem.baseUrl.replace(/\/extensions$/, '/portal')+'/v1/accounts/:accountId/users/:userId/contacts/:id'
      //transformResponse: transformResponse
    },
    count: {
      method: 'GET',
      url: baseRoute+'/contacts/count'
    }
  });

  Contacts.prototype.$save = function(success, error) {
    return (this.id) ? this.$update(success, error) : this.$create(success, error);
  };

  Contacts.prototype.toggleFavorite = function(isFavorite) {
    var baseUrl = $scSystem.baseUrl+'/v1/accounts/'+$scSystem.accountId+'/users/'+$scSystem.userId;
    if (isFavorite && this.id) {
      return $http.post(baseUrl+'/contacts/favorites/'+this.id);
    } else return $http.delete(baseUrl+'/contacts/favorites/'+this.id);
  };

  Contacts.layouts = function() { //simulate layouts API (missing for gunify contacts)
    return {
      $promise: {
        then: function(cb) {
          if (typeof cb === 'function') {
            cb([]);
          }
        }
      }
    };
  };

  return Contacts;
});