require('../../vendor/angular');
require('angular-messages');
require('angular-material');
require('angular-material/angular-material.scss');
require('../../vendor/angular-ui-router/ui-router-angularjs');
require('../../vendor/angular-ui-router/lib/legacy/stateEvents');
require('angular-resource');
require('angular-sanitize');
require('oclazyload');
require('angular-google-analytics');
require('../../vendor/ng-quill/ng-quill');

require('./providers');
require('./connectors');
require('./services');

require('./httpErrorInterceptor');

require('./core.scss');

// require('../common/directives/scHelp');
// require('../common/directives/scHelpPanel');
// require('../common/directives/scSections');
// require('../common/directives/scFieldPreview');

///// pasive listeners experimetal
function supportPassive() {
  var supported = false;
  try {
    var opts = Object.defineProperty({}, 'passive', {
      /* jshint ignore:start */
      //get() { supported = true; } //TODO getter?
      /* jshint ignore:end */
    });
    window.addEventListener("testPassives", null, opts);
    window.removeEventListener("testPassives", null, opts);
    supported = true;
  } catch (e) {
    console.error("core", e);
  }
  return supported;
}
if (typeof(EventTarget) !== 'undefined' && supportPassive()) {
  try {
    var superMethod = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function(type, listener, options) {
      var usesListenerOptions = typeof options === 'object';
      // var useCapture = usesListenerOptions ? options.capture : options;

      options = usesListenerOptions ? options : {};
      if (['touchmove','wheel','move'].indexOf(type) !== -1) {
        options.passive = options.passive !== undefined ? options.passive : true;
      }
      
      superMethod.call(this, type, listener, options);
    };
  } catch (e) {
    console.error('e', e);
  }
}

var defaultConfig = require('../defaultConfig');

var sc = angular.module('sc', [
  'ngMaterial',
  'ngMessages',
  'ngSanitize',
  'ui.router',
  'ui.router.state.events',
  'oc.lazyLoad',
  'angular-google-analytics',
  'ngQuill',
  'sc.providers',
  'sc.connectors',
  'sc.common.flash',
  'sc.errorsInterceptor',
  // 'sc.common.help', 
  // 'sc.common.sections',
  // 'sc.common.fieldPreview',
  // 'sc.common.helpPanel',
  'sc.services'
]);
sc.constant('$windowHelper', require('../common/helpers/windowHelper'));
sc.constant('$scStorage', require('../common/helpers/scStorage'));
sc.value('$scSystem', {
  baseUrl: (sessionStorage.baseUrl || defaultConfig.scServerHost).replace(/\/v1$/,''),
  token: sessionStorage.token,
  refreshToken: sessionStorage.refreshToken,
  userId: sessionStorage.userId || 0,
  accountId: sessionStorage.accountId || -1,
  corePort: (typeof(gU) !== 'undefined' && gU.storage) ? gU.runtime.connect({name: "core"}) : require('../common/helpers/webPort')
});
sc.value('$scPackages', {
  // c2d: { name: 'Click to Dial', active: true },
  // callHistory: { name: 'Call History', active: true },
  // callPop: { name: 'Call Screen Pop', active: true },
  // favContacts: { name: 'Favorite Contacts', active: true },
  // webLauncher: { name: 'Web Launcher', active: true },
  // presence: { name: 'Presence', active: false, disabled: true },
});

sc.constant('ngQuillConfig', {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline'],        // toggled buttons
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ]
  },
  theme: 'snow',
  placeholder: 'Description'
});

function primaryPallette() {
  switch(scVgisEnv) {
    case 'dev': return 'vgisPalette';
    case 'qa': return 'vgisPalette';
    case 'staging': return 'purple';
    default: return 'vgisPalette';
  }
}

sc.config(function($locationProvider, $stateProvider, $mdThemingProvider, $ocLazyLoadProvider, AnalyticsProvider, ngQuillConfigProvider, ngQuillConfig) {
  $locationProvider.hashPrefix(''); //for Firefox refresh to work
  AnalyticsProvider.setAccount(location.origin == 'https://extensions.gunify.vonage.com' ? 'UA-91637642-2' : 'UA-91637642-1');
  AnalyticsProvider.setPageEvent('$stateChangeSuccess');
  AnalyticsProvider.ignoreFirstPageLoad(true);
  AnalyticsProvider.setRemoveRegExp(/^\/[a-fA-F0-9]{6,}|\d+$/g);
  // if (scTesting) {
    AnalyticsProvider.disableAnalytics(true);
  // }

  $mdThemingProvider.definePalette('vgisPalette', {
    '50': 'e5e5e6',
    '100': 'bfbfbf',
    '200': '949595',
    '300': '454547',
    '400': '494a4b',
    '500': '363738',
    '600': '242526',
    '700': '1f1f20',
    '800': '131415',
    '900': '0f0f10',
    'A100': 'cfd8dc',
    'A200': '6294ff',
    'A400': '2f71ff',
    'A700': '155fff',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': [
      '50',
      '100',
      '200'
    ],
    'contrastLightColors': [
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      '900',
      'A100',
      'A200',
      'A400',
      'A700'
    ]
  });

  $mdThemingProvider.theme('default')
    .primaryPalette(primaryPallette())
    .accentPalette('deep-purple');
  
  $stateProvider.state('sc', {
    abstract: true,
    template: "<ui-view layout='column' flex></ui-view>",
    controller: require('./coreCtrl'),
    controllerAs: 'coreCtrl',
    params: {
      errorMsg: undefined
    },
    resolve: {
      scSystem: /*@ngInject*/ function($scSystem, $q, Analytics) {
        var defer = $q.defer();
        var done;
        function sysListener(event) {
          if (event.data && event.data.scSysResp) {
            $scSystem.token = sessionStorage.getItem('token');
            $scSystem.refreshToken = sessionStorage.getItem('refreshToken');
            $scSystem.userId = sessionStorage.getItem('wUserId') || sessionStorage.getItem('userId');
            $scSystem.accountId = sessionStorage.getItem('wAccountId') || sessionStorage.getItem('accountId');
            $scSystem.baseUrl = sessionStorage.getItem('baseUrl').replace(/\/v1$/,'');
            window.removeEventListener("message", sysListener. false);
            if (!$scSystem.token || ($scSystem.token === 'undefined' && !/main.html$/.test(location.pathname))) {
              if (window.scWebPortal) {
                gU.scSendMessage({ action: 'setSettings', settings: { token: 'invalid' } });
                setTimeout(function() {
                  location.assign('/login');
                }, 400);
              } else {
                location.assign('main.html#/auth');
              }
            }
            defer.resolve($scSystem);
            Analytics.set('&uid', $scSystem.userId);
            Analytics.set('&userId', $scSystem.userId);
            Analytics.set('&accountId', $scSystem.accountId);
            Analytics.set('&eId', event.data.scSysResp.eid);
            Analytics.set('&eName', event.data.scSysResp.name);
            Analytics.set('&eVersion', event.data.scSysResp.version);
            done = true;
            window.removeEventListener("message", sysListener);
            // if (scDebug) {
            //   console.warn('$scSystem');
            // }
          }
        }
        window.addEventListener("message", sysListener);
        window.postMessage({ scSysReq: true }, location);
        setTimeout(function() {
          if (!done) {
            window.postMessage({ scSysReq: true }, location);
          }
        }, 1000);
        setTimeout(function() {
          if (!done) {
            if (!window.scAllowWebLogin) {
              console.error('VGIS: Missing extension and no login option provided');
              location.assign('#/no_extension');
            } else {
              console.warn('VGIS: Missing extension. Fallback to WebLogin');
              defer.resolve({ 
                scWebLogin: true,
                baseUrl: defaultConfig.scServerHost
              });
            }
            window.removeEventListener("message", sysListener. false);
          }
        }, 1800);
        return defer.promise;
        //return $scSystem;
      },
      corePort: function (scSystem) {
        return scSystem.corePort;
      }
    }
  });

  $stateProvider.state('error', {
    url: '/error',
    template: require('./systemError.html'),
    controller: /*@ngInject*/ function($scope, $state, $stateParams) {
      $scope.errorMsg = $stateParams.errorMsg;
      $scope.retry = function() {
        if ($stateParams.toStateName) {
          $state.go($stateParams.toStateName, $stateParams.toParams);
        } else $state.go('sc.connectors.auth', null);  
      };
    },
    params: {
      toStateName: undefined,
      toParams: undefined,
      errorMsg: undefined
    }
  });
  $stateProvider.state('noExtension', {
    url: '/no_extension',
    template: require('./noExtension.html'),
    controller: /*@ngInject*/ function($scope, $state, $stateParams) {
      $scope.errorMsg = $stateParams.errorMsg;
      $scope.retryHref = "main.html" + $stateParams.toStateName ? $state.href($stateParams.toStateName) : '';
    },
    params: {
      toStateName: undefined,
      errorMsg: undefined
    }
  });

  $ocLazyLoadProvider.config({
    events: true
  });

  ngQuillConfigProvider.set(ngQuillConfig);
});

sc.run(function($rootScope, $state, $flash, Analytics) {
  $rootScope.$on('$stateChangeError', 
    function(event, toState, toParams, fromState, fromParams, error){
      if (error && error.detail && error.detail.status) {
        if ([401].indexOf(error.detail.status) !== -1) {
          //$flash.error = 'Session lost';
          if (window.scWebPortal) {
            gU.scSendMessage({ action: 'setSettings', settings: { token: 'invalid' } });
            setTimeout(function() {
              location.assign('/login');
            }, 400);
          } else if (window.scVisDom) {
            $state.go('sc.visdom.overview');
          } else {
            location.assign('main.html#/auth');
          }
        } else {
          var errorMsg = error.message;
          if (error.detail.status === -1) {
            errorMsg = 'Network unavailable';
          }
          if (error.detail.data) {
            errorMsg += '(' + error.detail.data.code + ' ' + error.detail.message +')';
          }
          $state.go('error', { toStateName: toState.name, toParams: toParams, errorMsg: errorMsg });
        }
      } else {
        $state.go('error', { toStateName: toState.name, toParams: toParams, errorMsg: error.msg });
      }
      Analytics.trackEvent('error', 'stateChange', error.message, error.code);
    }
  );
});

angular.element(document).ready(function () {
  angular.bootstrap(document, ['sc']);
});

var scPrevVersion = localStorage.getItem('scVersion');
if (scPrevVersion !== scPacketVersion) {
  localStorage.setItem('scVersion', scPacketVersion);
  if (scPrevVersion) {
    console.info('VGIS version change', scPrevVersion, '->', scPacketVersion);
  }
}

require('./msgTranslator');

module.exports = sc;