var windowDimensions = require('./windowDimensions');
var share = require('../../background/utils/share');

function pageMatch(current, requested, urlIncludesHash, urlStartsWith) {
  var currentStripped = current.replace(/#.*/, '');
  var requestedStripped = requested.replace(/#.*/, '');
  var match;
  if (urlStartsWith) {
    match = current.substring(0, requested.length) === requested;
  } else {
    match = currentStripped === requestedStripped;
  }
  if (match) {
    if (urlIncludesHash) {
      var hash = requested.match(/#.*$/);
      return (hash && current.indexOf(hash[0]) !== -1);   
    } else if (/activities.html/.test(requestedStripped)) { //activities exception if diff call
      var callIdMatch = requested.match(/activities.html#\/(.*)\//);
      return (callIdMatch && current.indexOf(callIdMatch[1]) !== -1); //current activity win not including reuested callId
    } else if (/settings.html/.test(requestedStripped)) {
      return (current.indexOf(requested) !== -1);
    } else return true;
  } else {
    if (currentStripped.replace(/(main|setup).html/, 'panel') === requested.replace(/(main|setup).html/, 'panel')) {
      return true; // setup and main opened in the same panel window
    }
    else return false;
  }
}

function openFromExtension(pageUrl, options, callback) {
  function tryCallback(window, tab, existing) {
    if (typeof callback === 'function') {
      var result = { windowId: window.id };
      if (tab) {
        result.tabId = tab.id;
      }
      if (existing) {
        result.existing = true
      }
      callback(result);
    }
  }

  return gU.windows.getAll({
    populate: true
  }, function (windows) {
    var i, isPresent, j, len, len1, tab;
    var tabQueries = [];
    var hasNormalWindowOpen = false;
    function enumTabs(win, tabs, done) {
      function focusWinActivateTab(tab) {
        if (options.hidden) {
          return;
        }
        prepareWindowOptions(function() {
          var updateOptions = { focused: true };
          if (options.type !== 'normal' && !isPresent) {
            updateOptions.width = options.w;
            updateOptions.height = options.h;
          }
          if (scBrowser !== 'firefox') {
            updateOptions.drawAttention = true;
          }
          gU.windows.update(tab.windowId, updateOptions, function() {
            tryCallback({ id: tab.windowId }, tab, true);
          });
          gU.tabs.update(tab.id, { active: true }); //switch to target tab
        });
      }
      for (j = 0, len1 = tabs.length; j < len1; j++) {
        tab = tabs[j];
        if (tab.url && pageMatch(tab.url, pageUrl, options.urlIncludesHash, options.urlStartsWith)) {
          isPresent = true;
          focusWinActivateTab(tab);
          return done();
        }
      }
      done();
    }
    function enumTabsWrapper(win) {
      tabQueries.push(win.id);
      gU.tabs.query({ windowId: win.id }, function (tabs) {
        enumTabs(win, tabs, function () {
          tabQueries.splice(tabQueries.indexOf(win.id), 1);
          if (!tabQueries.length && !isPresent) prepareWindowOptions(createWindow);
        });
      });
    }
    if (windows.length) {
      for (i = 0, len = windows.length; i < len; i++) {
        if (windows[i].type === 'normal') {
          hasNormalWindowOpen = windows[i].id;
        }
        enumTabsWrapper(windows[i]);
      }
    } else {
      prepareWindowOptions(createWindow);
    }
    function createWindow() {
      var windowOptions = {
        url: pageUrl,
        type: options.type
      };
      windowOptions.width = options.w;
      windowOptions.height = options.h;
      windowOptions.left = options.x;
      windowOptions.top = options.y;
      if (scBrowser !== 'firefox') {
        windowOptions.focused = true;
      }
      if (windowOptions.type === 'normal' && hasNormalWindowOpen) { // for regular links (hasNormalWindowOpen - Firefox workaround)
        return gU.tabs.create({ windowId: hasNormalWindowOpen, url: windowOptions.url }, function(tab) {
          tryCallback({ id: tab.windowId }, tab);
        });
      } else {
        var extensionWindowCreate = function() {
          gU.windows.create(windowOptions, function (window) {
            if (window) {
              var tab;
              if (window.tabs && window.tabs.length) {
                tab = window.tabs[0];
              }
              if (scMV3) {
                if (['panel', 'activity'].indexOf(options.scType) !== -1) {
                  if (tab && !(windowOptions.left || windowOptions.top)) {
                    var tabLoadListener = function (tabId , info) {
                      if (tabId === tab.id && info.status === 'complete') {
                        chrome.tabs.onUpdated.removeListener(tabLoadListener);
                        gU.tabs.sendMessage(tab.id, { action: 'getScreenSize'}, function(screenSize) {
                          calculateWindowPosition(screenSize);
                          gU.windows.update(window.id, { left: windowOptions.left, top: windowOptions.top }, function() {
                            tryCallback(window, tab);
                          });
                        });
                      }
                    }
                    chrome.tabs.onUpdated.addListener(tabLoadListener);
                  }
                }
              }
              if (scBrowser == 'firefox' && options.scType === 'panel' && /setup|main\.html/.test(windowOptions.url)) {
                gU.windows.update(window.id, { left: options.x, top: options.y }, function() {
                  tryCallback(window, tab);
                });
              } else {
                tryCallback(window, tab);
              }
            }
          });
        }
        var calculateWindowPosition = function (screenSize) {
          if (screenSize && screenSize.w && screenSize.h) {
            if (options.scType === 'panel') {
              windowOptions.left = screenSize.w - options.w - 30;
              windowOptions.top = screenSize.h - options.h - 30;
            } else {
              windowOptions.left = Math.round((screenSize.w / 2) - (options.w / 2))
              windowOptions.top = Math.round((screenSize.h / 2) - (options.h / 2))
            }
          }
        }
        if (!scMV3) {
          calculateWindowPosition({ w: window.screen.width, h: window.screen.height });
        }
        return extensionWindowCreate();
      }
    }
    function prepareWindowOptions(cb) {
      options.type = options.type ? options.type : 'panel';
      windowDimensions.get(options.scType, function (dims) {
        if (!options.h) {
          options.h = dims.h;
        }
        if (!options.w) {
          options.w = dims.w;
        }
        if (options.scType === 'panel') {
          if (!options.alwaysOnTop && scBrowser === 'chrome') {
            options.alwaysOnTop = true;
          }
          if (share.screenSize) {
            if (!options.x) {
              options.x = share.screenSize.w - options.w - 30;
            }
            if (!options.y) {
              options.y = share.screenSize.h - options.h - 30;
            }
          }
        } else if (options.scType === 'normal' || dims.openInTab) {
          if (share.screenSize) {
            options.w = share.screenSize.w;
            options.h = share.screenSize.h;
          }
          options.scType = 'normal';
          options.type = 'normal';
        } else {
          if (share.screenSize) {
            if (!options.x) {
              options.x = Math.round((share.screenSize.w / 2) - (dims.w / 2));
            }
            if (!options.y) {
              options.y = Math.round((share.screenSize.h / 2) - (dims.h / 2));
            }
          }
        }
        cb();
      });
    }
  });
}
var defaultConfig = require('../../defaultConfig');
var scStorage = require('./scStorage');
function openPopup(pageUrl, options, callback) {
  if (!pageUrl) {
    return callback && callback({ error: 'No URL' });
  }
  if (!options) {
    options = {};
  }
  scStorage.local.get('scWebHost', function (keys) {
    var fullPageUrl = pageUrl;
    options.scWebHost = keys.scWebHost;
    if (!/^http/.test(pageUrl)) {
      fullPageUrl = (keys.scWebHost || defaultConfig.scWebHost) + '/' + pageUrl;
    }
    if (gU.storage) {
      openFromExtension(fullPageUrl, options, callback);
    } else {
      gU.scSendMessage({
        action: 'openPopup',
        pageUrl: pageUrl,
        options: options
      }, callback);
    }
  });
}

var windowHelper = {
  openPopup: openPopup
};

module.exports = windowHelper;