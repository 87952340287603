require('./contact');
require('../services/oauth2');
angular.module('sc.connectors', [
  'ui.router',
  'sc.connectors.contact',
  'sc.services.auth2'
]).config(function($stateProvider) {
  $stateProvider.state('sc.connectors', {
    abstract: true,
    template: "<ui-view layout='column' flex></ui-view>"
  }).state('sc.connectors.auth', {
    url: '/auth?msg',
    onEnter: /*@ngInject*/ function($window) {
      $window.document.title = 'Vonage® Integration Suite :: Authorize';
    },
    template: require('./auth.html'),
    controller: require('./authCtrl'),
    controllerAs: "authCtrl",
    params: {
      connector: undefined,
      state: undefined
    }
  }).state('sc.connectors.authDone', {
    url: '/auth/done',
    template: 'done',
    controller: function() {
      // console.warn('auth done', location.href);
    }
  });
}).provider('$scConnectorSettings', function() {
  var self = this;
  self.settings = {};

  self.register = function(connectorId, settings) {
    self.settings = self.settings || {}; //early init issue
    self.settings[connectorId] = settings;
  };

  self.$get = function() {
    return {
      register: self.register,
      all: function() {
        return self.settings;
      }
    };
  };
}).provider('$scConnectors', function($scConnectorSettingsProvider) {
  var self = this;
  self.connectors = {};

  self.get = function(cId) {
    return self.connectors[cId];
  };

  self.register = function(connector) {
    self.connectors = self.connectors || {};
    if (connector.cId) {
      self.connectors[connector.cId] = connector;
    } else {
      console.error('Connector registration error', connector);
    }
    if (connector.settings) {
      $scConnectorSettingsProvider.register(connector.cId, connector.settings);
    }
  };

  self.clear = function() {
    for (var c in self.connectors) {
      delete self.connectors[c];
    }
  };

  self.$get = function() {
    return {
      register: self.register,
      get: self.get,
      all: self.connectors,
      clear: self.clear
    };
  };
});