require('./callRecordings');

var defaultConfig = require('../../defaultConfig');

angular.module('sc.services.calls', [
  'sc',
  'ngResource',
  'sc.services.callRecordings'
])
  .factory('CallsService', /*@ngInject*/ function ($http, $resource, $scSystem) {
    var baseRoute = $scSystem.baseUrl + '/v1/accounts/:accountId/users/:userId';
    var baseRouteV3 = $scSystem.baseUrl + '/v3/accounts/:accountId/users/:userId';

    
    function decorateCall(call) {
      if (!call) {
        return call;
      }
      if (call.activities && call.activities.length) {
        var activities = [];
        call.activityIcon = 'ic_subject_24px.svg';
        for (var a = 0; a < call.activities.length; a++) {
          if (call.activities[a]) {
            var activity = call.activities[a];
            if (activity._vgis) {
              if (activity._vgis.auto && activity._vgis.status === 'FAILURE') {
                call.activityIcon = 'ic_warning_24px.svg';
              }
              activities.push(activity._vgis);
            }
          }         
        }
        call.activities = activities;
      }
    }
//function not being used anywhere
function transformResponse(data, headersGetter, status) {
  var collection = angular.fromJson(data);
  if (status === 200 && collection.length) {
    for (var c = 0; c < collection.length; c++) {
      decorateCall(collection[c]);
    }
  }
  return collection;
}

var Calls = $resource(baseRoute + '/events/:eventId', {
  accountId: function () { return $scSystem.accountId; },
  userId: function () { return $scSystem.userId; },
  eventId: function(that) {
    if (that) {
      return that.id || that.eventId;
    }
  }
}, {
    update: {
      method: 'PUT'
    },
    query: {
      method: 'GET',
      isArray: true,
      transformResponse: transformResponse
    },
    queryWithActivitiesData: {
      method: 'GET',
      isArray: true
    },
    count: {
      method: 'GET',
      url: baseRoute + '/events/count'
    },
    answer: {
      method: 'PUT',
      url: baseRouteV3 + '/calls/:eventId/answer'
    },
    hold: {
      method: 'PUT',
      url: baseRouteV3 + '/calls/:eventId/hold'
    },
    unhold: {
      method: 'DELETE',
      url: baseRouteV3 + '/calls/:eventId/hold'
    },
    transfer: {
      method: 'POST',
      url: baseRouteV3 + '/calls/:eventId/transfer'
    },
    vmtransfer: {
      method: 'PUT',
      url: baseRouteV3 + '/calls/:eventId/vmtransfer'
    },
    assignContact: {
      method: 'POST',
      url: baseRoute + '/calls/:eventId/contact'
    }
  });

Object.defineProperty(Calls.prototype, 'eventId', {
  get: function () {
    return this.id;
  },
  set: function(id) {
    this.id = id;
  }
});

Calls.prototype.isActive = function () {
  return (defaultConfig.activeCallStates.indexOf(this.state) !== -1);
};

Calls.prototype.isTalking = function () {
  return (defaultConfig.talkingCallStates.indexOf(this.state) !== -1);
};

Object.defineProperty(Calls.prototype, 'durationInSeconds', {
  get: function () {
    if ((this.state || '').toUpperCase() === 'ANSWERED' && (!this.duration || this.ucpType === 'VBS')) { //try to fix invalid duration 0 from server for answered calls
      if(this.answerTime || this.startTime) {
        var fromTime = this.answerTime || this.startTime;
        if (typeof(fromTime) === 'string') {
          fromTime = Date.parse(fromTime);
        }
        var toTime = this.endTime || new Date().getTime();
        if (typeof(toTime) === 'string') {
          toTime = Date.parse(toTime);
        }
        this.duration = (toTime - fromTime);
      }
    }
    return (this.duration < 1000 ? 1 : this.duration / 1000);
  }
});

Object.defineProperty(Calls.prototype, 'voicemailUrl', {
  get: function () {
    return $scSystem.baseUrl + '/v3/accounts/' + $scSystem.accountId + '/users/' + $scSystem.userId + '/calls/' + this.id + '/voicemail?access_token=' + encodeURIComponent((sessionStorage.getItem('accessToken') || sessionStorage.getItem('token')));
  }
});

return Calls;
  });