var defaultConfig = require('../../defaultConfig');
angular.module('sc.services.auth2', [
]).provider('OAuth2', function () {
  var self = this;

  function baseUrl() {
    if (sessionStorage.getItem('scWebLogin')) {
      return '/extensions/v2';
    } else {
      var baseUrl = (sessionStorage.getItem('baseUrl') || '').replace('/v1', '');
      return baseUrl ? baseUrl + '/v2' : '/extensions/v2';
    }
  }

  function redirectUri() {
    return baseUrl() + '/auth/vgis/done';
  }

  function jsonToParams(obj) {
    var str = [];
    for (var p in obj) {
      if(obj[p]) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  }

  self.buildAuthorizeUrl = function (provider) {
    if ((provider || '').toLowerCase() === 'google') {
      if (sessionStorage.getItem('vgisAuthGoogle1') !== '1') {
        provider = 'google2';
      }
    }
    return baseUrl() + '/auth/authorize?' + jsonToParams({
      provider: provider || 'ucp',
      client_id: defaultConfig.authClientId,
      response_type: 'code',
      redirect_uri: redirectUri()
    });
  };

  self.getTokens = function (code, callback) {
    sessionStorage.removeItem('accessToken');
    if (!self.http) {
      self.http = self.injector.get('$http');
    }
    return self.http({
      method: 'POST',
      url: baseUrl() + '/auth/token',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      transformRequest: jsonToParams,
      data: {
        code: code,
        client_id: defaultConfig.authClientId,
        client_secret: defaultConfig.authSecret,
        grant_type: 'authorization_code',
        redirect_uri: redirectUri()
      },
      skipAuthorization: true
    }).then(function (response) {
      sessionStorage.setItem('accessToken', response.data.access_token);
      sessionStorage.setItem('refreshToken', response.data.refresh_token);
      var tokenExpire = new Date();
      tokenExpire.setSeconds(tokenExpire.getSeconds() + (parseInt(response.data.expires_in) - 6));
      sessionStorage.setItem('tokenExpire', tokenExpire.getTime());
      sessionStorage.setItem('wUserId', response.data.user_id);
      sessionStorage.setItem('wAccountId', response.data.account_id);
      sessionStorage.getItem('scWebLogin', true);
      callback(null, response.data);
    }, function (errorResponse) {
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('refreshToken');
      callback(errorResponse);
    });
  };

  self.refreshToken = function () {
    var sessionRefreshToken = sessionStorage.refreshToken;
    function performRefreshToken() {
      if (!self.http) {
        self.http = self.injector.get('$http');
      }
      return self.http({
        method: 'POST',
        url: baseUrl() + '/auth/token',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        transformRequest: jsonToParams,
        data: {
          grant_type: 'refresh_token',
          client_id: defaultConfig.authClientId,
          client_secret: defaultConfig.authSecret,
          refresh_token: sessionRefreshToken,
          redirect_uri: location.origin
        },
        skipAuthorization: true
      });
    }
    if (!self.rootScope) {
      self.rootScope = self.injector.get('$rootScope');
    }
    if (sessionRefreshToken) {
      return performRefreshToken();
    } else {
      return self.q.reject({ error: "Unable to restore the session" });
    }
    // if (self.rootScope && self.rootScope.portalAutorefresh && self.rootScope.portalAutorefresh.autoRefreshInterval) {
    //   return performRefreshToken();
    // } else {
    //   if (!self.mdDialog) {
    //     self.mdDialog = self.injector.get('$mdDialog');
    //   }
    //   return self.mdDialog.show(
    //     self.mdDialog.confirm()
    //       .title('Do you want to continue?')
    //       .textContent('Your current web session is about to expire.')
    //       .ok('Yes')
    //       .cancel('No')
    //   ).then(function () {
    //     return performRefreshToken();
    //   }, function() {
    //     location.assign('/login');
    //   });
    // }
  };

  self.getAuthorizationHeader = function () {
    var token = sessionStorage.getItem('accessToken') || sessionStorage.getItem('token');
    if (!token || (token === 'invalid' && typeof(scPortal) === 'undefined')) {
      return;
    }
    var tokenExpire = sessionStorage.getItem('tokenExpire');
    if (tokenExpire && parseInt(tokenExpire) < new Date().getTime()) {
      var defer = self.q.defer();
      if (sessionStorage.getItem('scWebLogin') || !sessionStorage.getItem('eid')) {
        self.refreshToken().then(function (response) {
          sessionStorage.setItem('accessToken', response.data.access_token);
          sessionStorage.setItem('refreshToken', response.data.refresh_token);
          var tokenExpire = new Date();
          tokenExpire.setSeconds(tokenExpire.getSeconds() + (parseInt(response.data.expires_in) - 6));
          sessionStorage.setItem('tokenExpire', tokenExpire.getTime());
          defer.resolve('Bearer ' + response.data.access_token);
        }, defer.reject);
      } else {
        gU.scSendMessage({ action: "refreshToken" }, function (response) {
          if (response.token) {
            var tokenExpire = new Date();
            tokenExpire.setSeconds(tokenExpire.getSeconds() + (parseInt(response.expiresIn) - 6));
            sessionStorage.setItem('tokenExpire', tokenExpire.getTime());
            sessionStorage.setItem('token', response.token);
            defer.resolve('Bearer ' + response.token);
          } else {
            defer.reject();
          }
        });
      }
      return defer.promise;
    } else {
      return 'Bearer ' + token;
    }
  };

  self.$get = function ($q, $injector) {
    self.injector = $injector;
    self.q = $q;
    return {
      getTokens: self.getTokens,
      refreshToken: self.refreshToken,
      getAuthorizationHeader: self.getAuthorizationHeader,
      buildAuthorizeUrl: self.buildAuthorizeUrl
    };
  };
});