angular.module('sc.providers', [
  'ui.router',
  'sc.services.providerAuth'
]).config(function($stateProvider) {
  $stateProvider.state('sc.providers', {
    abstract: true,
    template: "<ui-view layout='column' flex></ui-view>"
  });
}).provider('$scProviderSettings', function() {
  var self = this;
  self.settings = {};

  self.register = function(providerId, settings) {
    self.settings = self.settings || {}; //early init issue
    self.settings[providerId] = settings;
  };

  self.$get = function() {
    return {
      register: self.register,
      all: function() {
        return self.settings;
      }
    };
  };
}).provider('$scProviders', /*@ngInject*/ function($scProviderSettingsProvider) {
  var self = this;
  self.providers = {};

  self.get = function(pId) {
    return self.providers[pId];
  };

  self.register = function(provider) {
    self.providers = self.providers || {};
    if (provider.pId) {
      self.providers[provider.pId] = provider;
    } else {
      console.error('Provider registration error', provider);
    }
    if (provider.settings) {
      $scProviderSettingsProvider.register(provider.pId, provider.settings);
    }
  };

  self.$get = function() {
    return {
      register: self.register,
      get: self.get,
      all: self.providers
    };
  };
});