// require('angular-mocks');
// require('./mocks');

require('./user');
require('./calls');
require('./contacts');
require('./providerAuth');
require('./unifiedService');
require('./reports');
require('./callRecordings');
require('./fastdial');
require('./messages');

angular.module('sc.services', [
  // 'sc.services.mocks',
  'sc.services.unified',
  'sc.services.user',
  'sc.services.calls',
  'sc.services.contacts',
  'sc.services.reports',
  'sc.services.providerAuth',
  'sc.services.callRecordings',
  'sc.services.fastdial',
  'sc.services.messages'
]);
