require('./contactEdit.scss');

angular.module('sc.connectors.contact', [
  'ngMaterial',
  'sc.common.flash',
  'sc.services.contacts'
]).run(function ($injector) {
  var $scContactTabs = false;
  try {
    $scContactTabs = $injector.get('$scContactTabs');
  } catch (error) { }
  if ($scContactTabs) {
    // $scContactTabs.push({
    //   name: 'contactEdit',
    //   icon: "icons/ic_mode_edit_24px.svg",
    //   content: 'core/connectors/contact/contactEditTab.htm',
    //   hint: 'Edit Contact'
    // });
  }
})
.directive("scContactEditForm", function () {
  return {
    restrict: "E",
    scope: {
      scContact: '=',
      forCall: '='
    },
    template: require('./contactEditForm.html'),
    controller: require('./contactEditCtrl'),
    controllerAs: 'contactEditCtrl'
  };
});

require('ng-cache-loader?module=sc.connectors.contact,prefix=sc:**!./contactEditTab.htm');