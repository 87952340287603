require('../common/directives/flash');
require('./services/oauth2');
var AuthInProgress;
var refreshInProgress;

module.exports = angular.module("sc.errorsInterceptor", [
  'sc.common.flash',
  'ngMaterial',
  'sc.services.auth2'
]).config(function ($httpProvider) {
  $httpProvider.interceptors.push("HttpErrorInterceptor");
}).factory('HttpErrorInterceptor', function ($q, $flash, $injector, OAuth2) {
  return {
    request: function (config) {
      if (config.skipAuthorization) {
        return config;
      }
      if (!refreshInProgress) {
        var authorization = OAuth2.getAuthorizationHeader();
        if (typeof authorization === 'string') {
          config.headers.Authorization = authorization;
          return config;
        } else {
          refreshInProgress = authorization;
        }
      }
      if (!refreshInProgress) { //token known invalid
        return config;
      }
      return refreshInProgress.then(function (response) {
        config.headers.Authorization = response;
        refreshInProgress = undefined;
        return config;
      }, function () { //error like refresh token failed
        refreshInProgress = undefined;
        var $state = $injector.get('$state');
        if (window.scWebPortal) {
          $state.go('sc.portalLogin');
        } else if (window.scVisDom) {
          $state.go('sc.visdom.overview');
        } else {
          $state.go('sc.connectors.auth');
        }
        return config;
      });
    },
    response: function (response) {
      response.config.retry = false;
      var headers = response.headers();
      if (headers.date) {
        try {
          var serverDate = Date.parse(headers.date);
          gU.vgisTimeOffset = new Date().getTime() - serverDate;
        } catch(e) {}
      }
      delete $flash.error;
      return response;
    },
    responseError: function (rejection) {
      var defer = $q.defer();
      if (rejection.status === 401) {
        var $http = $injector.get('$http');
        if (!rejection.config.retry) {
          rejection.config.retry = true;
          if (sessionStorage.getItem('scWebLogin') || !sessionStorage.getItem('eid')) {
            // var $mdDialog = $injector.get('$mdDialog');
            // sessionStorage.removeItem('token');
            // if (!AuthInProgress) {
            //   AuthInProgress = $mdDialog.show({
            //     controller: require('../webAuth/loginCtrl'),
            //     controllerAs: 'loginCtrl',
            //     template: require('../webAuth/login.html'),
            //     parent: angular.element(document.body),
            //     clickOutsideToClose: true,
            //     escapeToClose: true
            //   });
            // }
            if (!AuthInProgress) {
              AuthInProgress = OAuth2.refreshToken();
            }
            AuthInProgress.then(function (response) {
              sessionStorage.setItem('accessToken', response.data.access_token);
              sessionStorage.setItem('refreshToken', response.data.refresh_token);
              var tokenExpire = new Date();
              tokenExpire.setSeconds(tokenExpire.getSeconds() + (parseInt(response.data.expires_in) - 6));
              sessionStorage.setItem('tokenExpire', tokenExpire.getTime());

              // if (rejection.config.url) {
              //   rejection.config.url = rejection.config.url.replace(/accounts\/-?\d+\/users\/\d+\//, '/accounts/' + response.data.account_id + '/users/' + response.data.user_id + "/");
              // }
              $http(rejection.config).then(function (resp) {
                return defer.resolve(resp);
              }, function (rej) {
                return defer.reject(rej);
              });
              AuthInProgress = undefined;
            }, function (rej) {
              defer.reject(rej);
              AuthInProgress = undefined;
            });
          } else {
            gU.scSendMessage({ action: "refreshToken" }, function (response) {
              if (response.token) {
                var tokenExpire = new Date();
                tokenExpire.setSeconds(tokenExpire.getSeconds() + (parseInt(response.expiresIn) - 6));
                sessionStorage.setItem('tokenExpire', tokenExpire.getTime());
                sessionStorage.setItem('token', response.token);
                $http(rejection.config).then(function (resp) {
                  return defer.resolve(resp);
                }, function (rej) {
                  return defer.reject(rej);
                });
              } else {
                $flash.error = "Refresh session failed.";
                setTimeout(function() {
                  var $state = $injector.get('$state');
                  if (window.scWebPortal) {
                    $state.go('sc.portalLogin');
                  } else if (window.scVisDom) {
                    $state.go('sc.visdom.overview');
                  } else {
                    $state.go('sc.connectors.auth');
                  }
                }, 500);
                Analytics.trackEvent('error', 'API', 'refreshSession', response.error);
                defer.reject(rejection);
              }
            });
          }
        } else defer.reject(rejection);
      } else if ([403, 405, 422, -1].indexOf(rejection.status) === -1) {
        var message, code;
        try {
          message = rejection.data.message;
          code = rejection.data.code;
        } catch (e) {
          if (scDebug) {
            console.error("e", e);
          }
        }
        if (rejection.data && rejection.data.code != "7040015") {
          var text = message || rejection.status + " (" + (code || rejection.statusText) + ")";
          if (rejection.data.code === "105004") {
            text = "Internal Server Error";
          }
          $flash.error = text;
        }
        defer.reject(rejection);
      } else {
        //console.warn('handle me!!!', rejection.status);
        defer.reject(rejection);
      }
      if (rejection.config && rejection.config.url && rejection.config.url.replace) {
        var Analytics = $injector.get('Analytics');
        Analytics.trackEvent('error', 'API', rejection.config.method + ' ' + rejection.config.url.replace(/accounts\/.*\/users\/\d+\//, 'accounts/*/users/*/'), rejection.status);
      }
      return defer.promise;
    }
  };
});