var listeners = [];

module.exports = {
  postMessage: function() {
   //'msg' is the unused parameter 
  },
  onMessage: {
    addListener: function(listener) {
      listeners.push(listener);
    },
    removeListener: function(listener) {
      var listenerIndex = listeners.indexOf(listener);
      if (listenerIndex >= 0) {
        listeners.splice(listenerIndex, 1);
      }
    },
  },
  broadcastMsg: function(msg) {
    for(var i in listeners) {
      if (typeof listeners[i] === 'function') {
        listeners[i](msg);
      } else listeners.splice(i, 1);
    }
  } 
};