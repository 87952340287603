/*
 * @Author: Alexander.Vangelov@vonage.com 
 * @Date: 2022-07-11 17:56:47 
 * @Last Modified by:   Alexander.Vangelov@vonage.com 
 * @Last Modified time: 2022-07-11 17:56:47 
 */

function asyncStore(storageType) {
  return {
    get: function (items, callback) {
      var val, resp = {};
      if (typeof items === 'string') {
        val = storageType.getItem(items);
        try {
          if (val) {
            resp[items] = (val[0] !== '{') ? val : JSON.parse(val);
          }
        } catch (err) {
          console.error(err.message, val);
        }
      } else if (items === null) { // chrome storage return all key-values in this case
        for (var key in storageType) {
          val = storageType.getItem(key);
          if (val) {
            resp[key] = (val[0] !== '{') ? val : JSON.parse(val);
          }
        }
      } else {
        for (var i in items) {
          if (items[i]) {
            val = storageType.getItem(items[i]);
            try {
              if (val) {
                resp[items[i]] = (val[0] !== '{') ? val : JSON.parse(val);
              }
            } catch (err) {
              console.error(err.message, val);
            }
          }
        }
      }
      if (typeof callback === 'function') {
        callback(resp);
      }
    },
    set: function (items, callback) {
      var resp = {};
      for (var i in items) {
        if (items[i]) {
           storageType.setItem(i, (typeof items[i] === 'string') ? items[i] : JSON.stringify(items[i]));
        }
      }
      if (typeof callback === 'function') {
        callback(resp);
      }
    },
    remove: function (items, callback) {
      if (typeof items === 'string') {
        storageType.removeItem(items);
      }
      else {
        for (var i in items) {
          if(items[i]) {
            storageType.removeItem(items[i]);
          }
        }
      }
      if (typeof callback === 'function') {
        callback();
      }
    }
  };
}

module.exports = {
  local: (gU.storage && gU.storage.local) ? gU.storage.local : asyncStore(localStorage),
  session: (gU.storage && gU.storage.session) ? gU.storage.session : asyncStore(sessionStorage)
};