var settingsHelper = require('../../common/helpers/settingsHelper');

angular.module('sc.services.user', [
  'sc',
  'ngResource'
])
.factory('UserService', /*@ngInject*/ function($http, $resource, $scSystem) {
  // if (scDebug) {
  //   console.warn('init UserService', $scSystem.baseUrl);
  // }
  var baseRoute = $scSystem.baseUrl+'/v3/accounts/:accountId/users/:userId';
  var baseRouteV4 = $scSystem.baseUrl+'/v4/accounts/:accountId/users/:userId';
  var portalRoute = '/portal/v1/accounts/:accountId/users/:userId';
  //unsed function
  // function transformResponse(data, headersGetter, status) {
  //   var collection = angular.fromJson(data);
  //   if (collection.calls) {
  //     return collection.calls;
  //   } else if (collection.contacts) {
  //     return [];
  //   } else return collection;
  // }

  var User = $resource(baseRoute, {
    accountId: function() { return sessionStorage.accountId; },
    userId: function() { return sessionStorage.userId; }
  }, {
    get: {
      timeout: 16000
    },
    update: { 
      method: 'PUT'
    },
    getIntegrationHealth: {
      method: 'GET',
      url: $scSystem.baseUrl+'/v2/accounts/:accountId/users/:userId/integrations/:connector/health'
    },
    getIntegrationProfile: {
      method: 'GET',
      url: $scSystem.baseUrl+'/v2/accounts/:accountId/users/:userId/integrations/:connector/profile',
      params: { t: function() { return new Date().getTime(); } }
    },
    getUciProfile: {
      method: 'GET',
      url: baseRouteV4+'/ucis/:uciId'
    },
    getUciProfileExtension: {
      method: 'GET',
      url: baseRoute+'/ucis/:uciId/extensions'
    },
    patchUciProfile: {
      method: 'PATCH',
      url: baseRoute+'/ucis/:uciId'
    },
    getPermissions: {
      method: 'GET',
      url: baseRoute+'/permissions'
    },
    getSettings: {
      method: 'GET',
      url: portalRoute+'/settings?keys='+settingsHelper.syncSettingsKeys.join(',') // in order to force the account level lookup, we must pass keys expicitly  
    },
    syncContacts: {
      method: 'POST',
      url: $scSystem.baseUrl.replace('v1', '')+'/v2/accounts/:accountId/users/:userId/import'
    },
    deleteSettings: {
      method: 'DELETE',
      url: '/portal/v1/accounts/:accountId/users/:userId/settings',
      // isArray: true
    }
  });

  User.prototype.feedback = function(survey) {
    var baseUrl = $scSystem.baseUrl+'/v3/accounts/'+$scSystem.accountId+'/users/'+$scSystem.userId;
    return $http.post(baseUrl+'/feedback', survey);
  };

  return User;
});