angular.module("sc.common.flash", [
  'ui.router'
])
.value('$flash', {})
.run(function($rootScope, $flash) {
  $rootScope.$on('$stateChangeStart', function() {
    $flash.error = undefined;
  });
})
.directive("scFlash", function($flash) {
  return {
    restrict: "AE",
    replace: true,
    template: '<md-toolbar ng-if="flashMsg()" md-medium-tall class="md-warn"><div class="md-toolbar-tools" style="overflow-y: auto; align-items: initial; font-size: 16px;">{{flashMsg()}}<span flex></span>'+
      '<md-button ng-click="clearError()" class="md-icon-button"><md-icon md-svg-src="icons/ic_close_24px.svg" aria-label="Hide error"></md-icon></md-button></div></md-toolbar>',
    link: function (scope) {
      scope.flashMsg = function() {
        return $flash.error;
      };
      scope.clearError = function() {
        $flash.error = undefined;
      };
    }
  };
});